.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.car-park-display {
  position: relative;
  width: 100%;
}

.car-park-display img {
  width: 100%;
}

.space-marker {
  position: absolute;
  background-image: url('/images/car-north.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 3.5%;
  height: 17%;
}

.space-marker img {
  width: 50%;
  top: 35%;
  position: absolute;
  left: 25%;
}

.river-lawn-1 {
  top: 4%;
  left:54%;
}

.river-lawn-2 {
  top: 4%;
  left: 37%;
}

.river-lawn-3 {
  top: 4%;
  left: 32.5%;
}
.river-lawn-4 {
  top: 4%;
  left: 28%;
}
.river-lawn-5 {
  top: 4%;
  left: 23.5%;
}
.river-lawn-6 {
  top: 20%;
  left: 7%;
}
.river-lawn-7 {
  top: 20%;
  left: 2.5%;
}
.river-lawn-8 {
  top: 69%;
  left: 2.5%;
  background-image: url('/images/car-south.png');
}
.river-lawn-9 {
  top: 69%;
  left: 7%;
  background-image: url('/images/car-south.png');
}
.river-lawn-10 {
  top: 4%;
  left: 41.5%;
}
.river-lawn-11 {
  top: 4%;
  left: 49%;
}
.river-lawn-12 {
  top: 4%;
  left: 58%;
}
.river-lawn-13 {
  top: 4%;
  left: 66%;
}
